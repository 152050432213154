/* eslint-disable react-hooks/exhaustive-deps*/
import { Helmet } from "react-helmet";
import { DECON_TEXT, RETIRED_ASSET } from "../../../../common/shared-constants";
import { useState } from "react";
import TableComp from "../AIMDashboard/components/TableComp/TableComp";
import { CLEAR_ALL_DECON_MESSAGES } from "./action-types";
import classes from "./AssetDecon.module.scss";
import { dateRenderer, nullRenderer } from "../AIMReports/utils/Renderer";

function Decon({
  fetchDeconData,
  deconAssets,
  deconAssetsLoading,
  deconErrorMessage,
  clearMessage,
  deconFilterData,
  allCampuses,
  onSearchDecon,
  onFilterSubmitDecon,
  onFilterResetDecon,
  errorMsgDecon,
  setErrorMsgDecon,
  filterConfigDecon,
  tableOptDecon,
  setOperationDecon,
  deconSearch,
  deconFilterInitialValues,
  barcodeReadDecon,
  manualScanInputDecon,
  onFocusSearch,
  manualSubmitDecon,
}: any) {
  //config for Filter options
  const [showEditModal, setShowEditModal] = useState(false);
  //Table Columns
  const commonObj = {
    align: "center",
    ellipsis: true,
  };
  const columns: any = [
    {
      ...commonObj,
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Control number",
      dataIndex: "controlNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Serial number",
      dataIndex: "serialNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Campus",
      dataIndex: "campus",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Status",
      dataIndex: "status",
      width: 40,
      render: (value: any, record: any) =>
        record.deleted ? RETIRED_ASSET : value || "-", //Check if deleted flag is there & show status
    },
    {
      ...commonObj,
      title: "Check In",
      dataIndex: "checkInTime",
      width: 40,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Check In by",
      dataIndex: "checkInBy",
      width: 60,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Decon Start",
      dataIndex: "deconStart",
      width: 40,
      render: dateRenderer,
    },
    {
      ...commonObj,
      title: "Decon Start by",
      dataIndex: "deconStartedBy",
      width: 50,
      render: nullRenderer,
    },
  ];

  return (
    <>
      {/* Hidden input field for barcode focussing purpose */}
      <input
        id="hiddenInputDecon"
        autoFocus
        autoComplete="false"
        className={classes.hiddenInput}
      />
      <Helmet>
        <title>Asset Checkin-Decon | AIM</title>
      </Helmet>
      <div className="deconTable">
        <TableComp
          title="Asset Decon"
          from={DECON_TEXT}
          data={deconAssets.assets}
          dataPagination={deconAssets.pagination}
          onSearch={onSearchDecon}
          onFilterSubmit={onFilterSubmitDecon}
          onFilterReset={onFilterResetDecon}
          columns={columns}
          errorMessage={deconErrorMessage || errorMsgDecon}
          loading={deconAssetsLoading}
          clearMessages={() => {
            clearMessage(CLEAR_ALL_DECON_MESSAGES);
            setErrorMsgDecon("");
            document.getElementById("hiddenInputDecon")?.focus();
          }}
          filterData={deconFilterData}
          filterConfig={filterConfigDecon}
          tableConfig={tableOptDecon}
          changeTableConfig={(tableConf: any) => {
            setOperationDecon(tableConf);
            fetchDeconData({
              filter: deconFilterData,
              search: deconSearch,
              ...tableConf,
            });
            document.getElementById("hiddenInputDecon")?.focus();
          }}
          filterInitialValues={deconFilterInitialValues}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          allCampuses={allCampuses}
          scannedAsset={barcodeReadDecon}
          onSearchKeyPress={manualScanInputDecon} //deconAfterScan(barcodeRead);
          onFocusSearch={onFocusSearch}
          manualSubmitDecon={manualSubmitDecon}
        />
      </div>
    </>
  );
}

export default Decon;
