/* eslint-disable react-hooks/exhaustive-deps*/
import { Row, Card, Alert, Tooltip, Typography } from "antd";
import classes from "./home.module.scss";
import Truck from "../../../images/truck.png";
import Pointer from "../../../images/pointer.png";
import Click from "../../../images/click.png";
import PatientTransport from "../../../images/patientTransport.png";
import { useHistory } from "react-router-dom";
import { IRootState } from "../../../common/redux/reducers";
import { connect } from "react-redux";
import { setSuccessMessage } from "./action";
import { saveAlert } from "../patient-transport/actions";
import { useEffect, useState } from "react";
import { PhoneOutlined } from "@ant-design/icons";
import BackgroundCalls from "../../shared/background-calls";
import {
  DELIVERY_REQUEST_TYPE_ID,
  DISCHARGE_REQUEST_TYPE_ID,
  PICKUP_REQUEST_TYPE_ID,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  SHARPS_REQUEST_TYPE_ID,
  TRANSFER_REQUEST_TYPE_ID,
} from "../../../common/shared-constants";

const HomePage = ({
  successMessage,
  setSuccessMessage,
  currentCampus = {},
  saveAlert,
  saveAlertValue,
}: any) => {
  const history = useHistory();
  const [isPatientTransportAllowed, setIsPatientTransportAllowed] =
    useState(false);
  const [allowedRequestTypesIds, setAllowedRequestTypesIds] = useState<any>([]);
  const [notAllowed, setNotAllowed] = useState<boolean>(false);

  useEffect(() => {
    const campusRequestTypesIds = currentCampus?.requestTypes?.map(
      (requestType: any) => requestType?.id
    );
    if (campusRequestTypesIds?.length === 0) setNotAllowed(true);
    else setNotAllowed(false);
    setAllowedRequestTypesIds(campusRequestTypesIds);
    const isPatientRequestAllowed =
      campusRequestTypesIds?.includes(PROCEDURE_REQUEST_TYPE_ID) ||
      campusRequestTypesIds?.includes(RETURN_REQUEST_TYPE_ID) ||
      campusRequestTypesIds?.includes(DISCHARGE_REQUEST_TYPE_ID) ||
      campusRequestTypesIds?.includes(TRANSFER_REQUEST_TYPE_ID);
    setIsPatientTransportAllowed(isPatientRequestAllowed);
  }, [currentCampus]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
      }, 15000);
    }
    return () => {
      setSuccessMessage("");
    };
  }, []);

  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      className={classes.homeContainer}
    >
      {currentCampus.bannerStatus && currentCampus.bannerMessage && (
        <span className={classes.bannerText}>
          <div className={classes.titleText}>Advisory Notice:</div>
          {currentCampus.bannerMessage}
        </span>
      )}
      {successMessage && (
        <Alert
          message={
            saveAlertValue ? (
              <div>
                <p className={classes.successMessage}>{successMessage}</p>
                <p className={classes.bannerMessage}>
                  <PhoneOutlined /> The service requested falls outside normal
                  limits and we may not be able to get to you or your patient in
                  enough time.
                </p>
                <p className={classes.successMessage}>
                  Please call to confirm your request.” Transport Ext:
                  5-6222(Option 3)
                </p>
              </div>
            ) : (
              successMessage
            )
          }
          type="success"
          showIcon
          closable
          className={`fadeIn ${classes.homeAlertVisible}`}
          style={{ zIndex: 111 }}
        />
      )}
      {notAllowed && (
        <div>
          <Typography>
            This campus don't have access to any requests.
          </Typography>
        </div>
      )}
      {allowedRequestTypesIds?.includes(DELIVERY_REQUEST_TYPE_ID) && (
        <div
          style={successMessage ? { marginTop: "5px" } : {}}
          className={classes.cardContainer}
          onClick={() => {
            saveAlert(false);
            history.push("/request/equipment-delivery");
          }}
        >
          <Tooltip title="Equipment Delivery">
            <Card
              bordered={false}
              className={classes.truckCard}
              bodyStyle={{ padding: "0px" }}
            >
              <img alt="" src={Truck} className={classes.truck}></img>
              <Typography className={classes.truckText}>
                Equipment Delivery
              </Typography>
            </Card>
          </Tooltip>
        </div>
      )}
      {allowedRequestTypesIds?.includes(PICKUP_REQUEST_TYPE_ID) && (
        <div
          style={successMessage ? { marginTop: "5px" } : {}}
          className={classes.cardContainer}
          onClick={() => {
            saveAlert(false);
            history.push("/request/equipment-pickup");
          }}
        >
          <Tooltip title="Equipment Pickup">
            <Card
              bordered={false}
              className={classes.pointerCard}
              bodyStyle={{ padding: "0px" }}
            >
              <img alt="" src={Pointer} className={classes.pointer}></img>
              <Typography className={classes.pointerText}>
                Equipment Pickup
              </Typography>
            </Card>
          </Tooltip>
        </div>
      )}
      {allowedRequestTypesIds?.includes(SHARPS_REQUEST_TYPE_ID) && (
        <div
          style={successMessage ? { marginTop: "5px" } : {}}
          className={classes.cardContainer}
          onClick={() => {
            saveAlert(false);
            history.push("/request/sharps");
          }}
        >
          <Tooltip title="Sharps Request">
            <Card
              bordered={false}
              className={classes.clickCard}
              bodyStyle={{ padding: "0px" }}
            >
              <img alt="" src={Click} className={classes.click}></img>
              <Typography className={classes.clickText}>
                Sharps Request
              </Typography>
            </Card>
          </Tooltip>
        </div>
      )}
      {isPatientTransportAllowed && (
        <div
          style={successMessage ? { marginTop: "5px" } : {}}
          className={classes.cardContainer}
          onClick={() => {
            saveAlert(false);
            history.push("/request/patient-transport");
          }}
        >
          <Tooltip title="Patient Transport">
            <Card
              bordered={false}
              className={classes.clickCard}
              bodyStyle={{ padding: "0px" }}
            >
              <img
                alt=""
                src={PatientTransport}
                className={classes.pateintTransport}
              ></img>
              <Typography className={classes.pateintTransportText}>
                Patient Transport
              </Typography>
            </Card>
          </Tooltip>
        </div>
      )}
      <BackgroundCalls />
    </Row>
  );
};

const mapStateToProps = ({ homeReducer, transportMangement }: IRootState) => ({
  successMessage: homeReducer.successMessage,
  currentCampus: homeReducer.currentCampus,
  saveAlertValue: transportMangement.alertMessage,
});

const mapDispatchToProps = {
  setSuccessMessage,
  saveAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
