/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import classes from "./request-edit-modal.module.scss";
import {
  Card,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Select,
  Input,
  Empty,
  Alert,
  message,
} from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import ItemCard from "../../commons/ItemCard";
import { useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../../common/redux/reducers";
import { fetchAllCampuses } from "../../../campus/actions";
import { useEffect } from "react";
import {
  fetchPriorities,
  fetchUnitsOnCampus,
  updateRequestDetails,
} from "../actions";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { getCatalogueIdByRequestType } from "../../../../../common/utils/catalogue";
import { clearMessage } from "../../../../../common/actions/common-actions";
import { CLEAR_REQUEST_MESSAGE } from "../action-types";
import AddItemModal from "../../../../../common/components/AddItemModal/add-item-modal";
import {
  contactNameValidator,
  emailValidator,
  locationRoomNameValidator,
  phoneNumberValidator,
  shortDescriptionValidator,
} from "../../../../../common/utils/formValidators";
import {
  DELIVERY_REQUEST_TYPE_ID,
  PATIENTMODAL_SINGLE_NEW,
  PATIENTMODAL_SINGLE_REMOVE,
  PATIENTMODAL_VIEW,
  PICKUP_REQUEST_TYPE_ID,
  reqTypeAddItemMap,
  REQ_EDIT,
} from "../../../../../common/shared-constants";
import {
  getESTFromUTC,
  getFormattedDate,
} from "../../../../../common/utils/time";
import PatientDetailsModal from "../../../../../common/components/PatientDetailsModal/patient-details-modal";
import DelayModal from "../../../../../common/components/DelayModal/DelayModal";

const fetchItems = ({ unitId, catalogueId, campusId }: any) => {
  return adminRootInstance
    .get(`item/list?catalogue=${catalogueId}&unit=${unitId}&campus=${campusId}`)
    .then((res: any) => {
      return { success: true, items: res.data.data };
    })
    .catch((err: any) => {
      return { success: false, errMsg: err.response.data.message };
    });
};

const RequestEditModal = (props: any) => {
  const {
    showModal,
    toggleModal,
    cardHeaderStyle,
    cardsBodyStyle,
    updateRequestDetails,
    requestDetails,
    fetchPriorities,
    fetchedPriority,
    prioritiesLoading,
    fetchAllCampuses,
    allCampuses,
    campusesLoading,
    fetchUnitsOnCampus,
    fetchedUnits = [],
    unitsLoading,
    errorMsg,
    clearMessage,
    editReqLoading,
  } = props;
  const {
    reqInfo: {
      reqId = "",
      reqType: { id: reqTypeId = "" } = {},
      description = "",
      status: reqStatus = "",
      items = [],
      created: reqCreatedDate = "",
      priority: { id: priorityId = "", name: priorityName = "" } = {},
    } = {},
    locationInfo: {
      campus: { id: campusId = 1, name: campusName = "" } = {},
      unit: { id: unitId = 2, name: unitName = "" } = {},
      roomName = "",
    } = {},
    contactInfo: {
      id: userId = "",
      name: contactName = "",
      phoneNumber = "",
      mailId = "",
    } = {},
    scanned = false,
  } = requestDetails || {};
  const initialFormValues = {
    campusId: campusId || "",
    unitId: unitId || "",
    roomName: roomName || "",
    contactName: contactName || "",
    contactPhone: phoneNumber,
    contactEmail: mailId || "",
    slaId: priorityId,
    userId: userId || "",
    additionalNotes: description || "",
  };
  const [selectedUnitId, setSelectedUnit] = useState<any>(unitId);
  const [dynamicItems, setDynamicItems] = useState<any>([]);
  const [addItemModal, setAddItemModal] = useState(false);
  const [tempCart, setTempCart] = useState([
    ...items.map((a: any) => ({ ...a })),
  ]);
  const [itemsErr, setItemsErr] = useState("");
  const [form] = Form.useForm();
  const [showPatientModal, setShowPatientModal] = useState<any>(false);
  const [showItemModal, setShowItemModal] = useState<any>();
  const onUnitChange = (unitId: any) => {
    const campusId = form.getFieldsValue()?.campusId;
    if (tempCart.length !== 0) {
      const unitSpecificItem = tempCart.filter(({ item }: any = {}) => {
        const allUnitsMapped = item?.unitIds?.includes(1);
        const allCampusesMapped = item?.campusIds?.includes(1);
        if (allUnitsMapped && allCampusesMapped) {
          return false;
        } else if (
          item?.campusIds?.includes(campusId) &&
          (item?.unitIds?.includes(unitId) || allUnitsMapped)
        ) {
          return false;
        } else if (allCampusesMapped && item?.unitIds?.includes(unitId)) {
          return false;
        } else return true;
      });
      if (unitSpecificItem.length > 0) {
        setShowItemModal({ unitId, unitSpecificItem });
      } else {
        setSelectedUnit(unitId);
        form.setFieldsValue({ unitId });
      }
    } else {
      setSelectedUnit(unitId);
      form.setFieldsValue({ unitId });
    }
  };

  useEffect(() => {
    if (showModal) {
      //get all campuses
      fetchAllCampuses(true);
      //fetch sla's based on the request type id
      fetchPriorities({ reqTypeId, campusId });
      //fetch units list based on initial campus
      fetchUnitsOnCampus({ campusId: campusId });
    }
    return () => {
      clearMessages();
      form.setFieldsValue(initialFormValues);
      setTempCart([...items.map((a: any) => ({ ...a }))]);
    };
  }, [showModal]);

  //on campus value change, fetch data for units dropdown
  const onCampusChangeHandler = (campusId: any) => {
    form.setFieldsValue({ unitId: "" }); //since unit it depends on campusId
    setSelectedUnit("");
    setDynamicItems([]); //we clear this since available items are based on unit id & catalogue id
    fetchUnitsOnCampus({ campusId });
    form.setFieldsValue({ slaId: "" });
    fetchPriorities({ reqTypeId, campusId });
  };

  const clearMessages = () => {
    clearMessage(CLEAR_REQUEST_MESSAGE);
  };

  const openAddItemModal = async (selectedCampusId: number) => {
    if (selectedUnitId) {
      const res: any = await fetchItems({
        unitId: selectedUnitId,
        catalogueId: getCatalogueIdByRequestType(reqTypeId),
        campusId: selectedCampusId,
      });
      if (res?.success) {
        setDynamicItems([...res.items]);
        setAddItemModal(true);
      } else {
        message.error(res.errMsg);
      }
    }
  };

  const onItemsSelected = (newItems: any) => {
    setTempCart(newItems);
    setAddItemModal(false);
    setItemsErr("");
  };

  const onUpdateFinish = async (values: any) => {
    if (!tempCart.length) {
      setItemsErr("Add atleast one item.");
      return;
    }
    const updatedData = {
      ...values,
      items: tempCart,
      room: values?.room?.trim(),
      contactName: values?.contactName?.trim(),
      contactEmail: values?.contactEmail?.trim(),
      additionalNotes: values?.additionalNotes?.trim(),
    };
    const res = await updateRequestDetails({ reqId, updatedData });
    if (res?.success) {
      toggleModal();
    }
  };
  //display sla disclaimer on change
  const onPriorityChange = (slaId: any) => {
    const sla = fetchedPriority.find((ele: any) => ele.id === slaId);
    if (sla.disclaimerStatus) {
      info(sla.disclaimerInfo);
    }
  };

  const [showDelayModal, setShowDelayModal] = useState("");
  const info = (data: any) => {
    setShowDelayModal(data);
  };

  //function to modify the quantity
  const quantityEdit = (itemId: any, action: any, newQuantity?: number) => {
    const items = tempCart.map((cartItem: any) => {
      const getPatientsCond =
        reqTypeId === DELIVERY_REQUEST_TYPE_ID &&
        (cartItem.item.specialtyItem ||
          cartItem.item.specialityItem ||
          cartItem.isSpecialtyItem);
      if (cartItem.item.id === itemId) {
        if (action === "INCREASE") {
          if (getPatientsCond) {
            setShowPatientModal({ id: itemId, mode: PATIENTMODAL_SINGLE_NEW });
          } else cartItem.quantity += 1;
        } else if (action === "EDIT") {
          if (cartItem.item.specialtyItem || cartItem.item.specialityItem) {
            //open the modal to remove patient based on the number of quantity (old - newQuantity)
            //if submitted, decrement
            //else keep the quantity same
          }
          cartItem.quantity = newQuantity;
        } else {
          if (getPatientsCond) {
            cartItem?.patients?.length &&
              setShowPatientModal({
                id: itemId,
                mode: PATIENTMODAL_SINGLE_REMOVE,
              });
          } else cartItem.quantity -= 1;
        }
      }
      return cartItem;
    });
    setTempCart(items);
  };
  //function to remove item form the cart
  const onItemRemove = (removedId: any) => {
    let itemRemoving = [...tempCart];
    itemRemoving = itemRemoving.filter(
      (cartItem) => cartItem.item.id !== removedId
    );
    setTempCart(itemRemoving);
  };
  let patientDetailsPresent =
    tempCart.reduce(
      (acc: any, item: any) => (item.patients ? acc + 1 : acc),
      0
    ) > 0;
  let disableCards = scanned;
  return (
    <div>
      <Modal
        title={<div className={classes.modalHeader}>Request edit</div>}
        centered
        visible={showModal}
        onCancel={toggleModal}
        style={{ minWidth: "90vw" }}
        footer={null}
        bodyStyle={{
          backgroundColor: "rgb(240 242 245)",
          padding: "10px 24px",
        }}
        maskClosable={false}
      >
        <Row>
          {errorMsg ? (
            <Alert
              className={`fadeIn ${classes.alertContainer}`}
              message={errorMsg}
              style={{ width: "100%" }}
              type="error"
              closable
              afterClose={clearMessages}
            />
          ) : null}
        </Row>
        <Row className={classes.nonEditableRow}>
          <Col span={7} className={classes.reqId}>
            Request ID : {reqId}
          </Col>
          <Col span={10} className={classes.dateAndTime}>
            Date:{" "}
            {process.env.REACT_APP_SSO_ENABLED
              ? getFormattedDate(reqCreatedDate)
              : getESTFromUTC(reqCreatedDate)}
          </Col>
          <Col span={7} className={classes.status}>
            Status: {reqStatus}
          </Col>
        </Row>
        <Form
          {...{
            labelCol: { span: 10 },
            labelAlign: "left",
            colon: false,
            wrapperCol: { span: 14 },
          }}
          initialValues={initialFormValues}
          onFinish={onUpdateFinish}
          form={form}
        >
          <Row>
            <Col
              span={12}
              style={{
                padding: "10px 20px 10px 0px",
                cursor: disableCards ? "not-allowed" : "default",
              }}
            >
              {/* first form start */}
              <Card
                title="Location Info"
                headStyle={cardHeaderStyle}
                bodyStyle={{
                  ...cardsBodyStyle,
                  textAlign: "left",
                  padding: "20px 100px 20px 15px",
                }}
                className={`${classes.cardStyle} ${
                  disableCards && classes.disableEvents
                }`}
              >
                <Form.Item
                  label="Campus name"
                  name="campusId"
                  className={classes.formField}
                  rules={[
                    () => ({
                      required: true,
                      message: "Please select a campus.",
                    }),
                  ]}
                >
                  <Select
                    placeholder="Enter campus name"
                    defaultValue={campusId}
                    loading={campusesLoading}
                    key="campusId"
                    onChange={onCampusChangeHandler}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {campusesLoading ? (
                      <Select.Option value={campusId}>
                        {campusName}
                      </Select.Option>
                    ) : (
                      allCampuses?.map((campus: any, index: Number) => (
                        <Select.Option
                          value={campus.id}
                          key={"campusId" + index}
                        >
                          {campus.name}
                        </Select.Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="Unit name"
                  name="unitId"
                  rules={[
                    () => ({
                      required: true,
                      message: "Please select a unit.",
                    }),
                  ]}
                >
                  <Select
                    placeholder="Enter unit name"
                    defaultValue={unitId}
                    loading={unitsLoading}
                    onChange={(val, e: any) => {
                      onUnitChange(val);
                    }}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {unitsLoading ? (
                      <Select.Option value={unitId}>{unitName}</Select.Option>
                    ) : (
                      fetchedUnits?.map((unit: any, index: Number) => (
                        <Select.Option value={unit.id} key={"unitId" + index}>
                          {unit.name}
                        </Select.Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="Room No/Bedspace"
                  name="room"
                  initialValue={roomName}
                  rules={[
                    () => ({
                      required: true,
                      validator: locationRoomNameValidator,
                    }),
                  ]}
                >
                  <Input
                    placeholder="Enter Room No/Bedspace"
                    defaultValue={roomName}
                  />
                </Form.Item>
              </Card>
            </Col>{" "}
            <Col span={12} style={{ paddingTop: "10px" }}>
              <Card
                title="Contact Info"
                headStyle={cardHeaderStyle}
                bodyStyle={{
                  ...cardsBodyStyle,
                  textAlign: "left",
                  padding: "20px 100px 20px 15px",
                }}
                className={classes.cardStyle}
              >
                <Form.Item className={classes.formField} name="userId" hidden />
                <Form.Item
                  className={classes.formField}
                  label="Name"
                  name="contactName"
                  rules={[
                    () => ({
                      required: true,
                      validator: contactNameValidator,
                    }),
                  ]}
                >
                  <Input placeholder="Enter name" defaultValue={contactName} />
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label="Phone number"
                  name="contactPhone"
                  rules={[
                    {
                      required: true,
                      validator: phoneNumberValidator,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter phone number"
                    type="tel"
                    defaultValue={phoneNumber}
                  />
                </Form.Item>
                <Form.Item
                  className={classes.formField}
                  label={<span style={{ paddingLeft: "10px" }}>Email ID</span>}
                  name="contactEmail"
                  rules={[
                    {
                      required: false,
                      validator: emailValidator,
                      message: "Please enter valid email.",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Email ID"
                    type="email"
                    defaultValue={mailId}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card
              title="Request Info"
              headStyle={cardHeaderStyle}
              bodyStyle={{
                ...cardsBodyStyle,
                display: "flex",
                flexDirection: "row",
                textAlign: "left",
                padding: "20px 20px 20px 15px",
              }}
              className={classes.cardStyle}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {!(reqTypeId === 4 || reqTypeId === 5) && (
                  <Row className={classes.slaDescriptionContainer}>
                    <Col span={12} className={classes.fieldContainer}>
                      <Form.Item
                        className={`${classes.sla} ${classes.formField}`}
                        label="Priority"
                        labelCol={{ span: 4 }}
                        name="slaId"
                        rules={[
                          () => ({
                            required: true,
                            message: "Please select a priority.",
                          }),
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          loading={prioritiesLoading}
                          onChange={onPriorityChange}
                          filterOption={(input: any, option: any) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {prioritiesLoading ? (
                            <Select.Option value={priorityId}>
                              {priorityName}
                            </Select.Option>
                          ) : (
                            fetchedPriority?.map((sla: any, index: Number) => (
                              <Select.Option
                                value={sla.id}
                                key={"priorityValue" + index}
                              >
                                {sla.name}
                              </Select.Option>
                            ))
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={classes.fieldContainer}>
                      <Form.Item
                        className={`fullWidth ${classes.formField}`}
                        label={
                          <span
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            Short description
                          </span>
                        }
                        name="additionalNotes"
                        labelCol={{ span: 5 }}
                        rules={[
                          {
                            validator: shortDescriptionValidator,
                            required: false,
                          },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 1, maxRows: 20 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <div
                  style={{
                    width: "100%",
                    cursor: disableCards ? "not-allowed" : "default",
                  }}
                >
                  <div
                    className={`${classes.dataSectionHeader} ${
                      disableCards && classes.disableEvents
                    }`}
                  >
                    <div style={{ width: "50%" }}>
                      <b>Items</b>
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <Button
                        className={classes.button}
                        type="primary"
                        onClick={() =>
                          openAddItemModal(form.getFieldsValue().campusId)
                        }
                        disabled={!selectedUnitId}
                        title={!selectedUnitId ? "Select a unit first." : ""}
                      >
                        + Add New Item
                      </Button>
                    </div>
                  </div>
                  {itemsErr ? (
                    <Alert
                      className={`fadeIn ${classes.alertContainer}`}
                      message={itemsErr}
                      type="error"
                      closable
                      afterClose={() => setItemsErr("")}
                    />
                  ) : null}
                  <div className={classes.scrollableRow}>
                    {/* Scrollable row */}
                    {tempCart.length ? (
                      tempCart.map((item: any) => {
                        const hasOrderSplFlag =
                          Object.keys(item).includes("isSpecialtyItem");
                        return ItemCard({
                          disabled: disableCards,
                          itemObj: item.item,
                          name: item.item.name,
                          quantity: item.quantity,
                          image: item.item.image,
                          editable: true,
                          itemId: item.item.id,
                          delay: item.item.delay,
                          reqTypeId,
                          specialityItem: !!(hasOrderSplFlag
                            ? item?.isSpecialtyItem
                            : item?.item?.specialtyItem),
                          propsToEdit: {
                            quantityEdit: quantityEdit,
                            onItemRemove: onItemRemove,
                          },
                          setDetails: () => {
                            setShowPatientModal({
                              id: item.item.id,
                              mode: PATIENTMODAL_VIEW,
                            });
                          },
                          viewPatientProps: {
                            patients: item?.patients || [],
                            patientDetailsPresent,
                          },
                        });
                      })
                    ) : (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <Empty
                          description="Cart is empty"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <div className={classes.footer}>
              <Button
                type="default"
                className={`${classes.cancelButton} `}
                onClick={(e) => {
                  e.preventDefault();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={classes.button}
                htmlType="submit"
                loading={editReqLoading}
              >
                Update
              </Button>
            </div>
          </Row>
        </Form>
        {addItemModal && (
          <AddItemModal
            allItems={dynamicItems}
            visible={addItemModal}
            handleCancel={() => setAddItemModal(false)}
            onItemsSelected={onItemsSelected}
            editCart={tempCart}
            type={reqTypeAddItemMap[reqTypeId] || REQ_EDIT}
            showDelayFlag={reqTypeId !== PICKUP_REQUEST_TYPE_ID}
            showSpecialityFlag={reqTypeId === DELIVERY_REQUEST_TYPE_ID}
            patientDetails
          />
        )}
      </Modal>
      {reqTypeId === DELIVERY_REQUEST_TYPE_ID && (
        <PatientDetailsModal
          visible={!!showPatientModal}
          toggleModal={() => setShowPatientModal(false)}
          cart={tempCart}
          setCart={setTempCart}
          itemId={showPatientModal.id}
          mode={showPatientModal.mode}
        />
      )}
      <DelayModal
        key="delay-modal"
        handleCancel={() => {
          setShowDelayModal("");
        }}
        handleOk={() => {
          setShowDelayModal("");
        }}
        isModalVisible={!!showDelayModal}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text={showDelayModal}
        okayText="Okay"
        hideCancel={true}
      />
      <DelayModal
        key="item-clash-modal"
        handleCancel={() => {
          form.setFieldsValue({ unitId: selectedUnitId });
          setShowItemModal(undefined);
        }}
        handleOk={() => {
          form.setFieldsValue({ unitId: showItemModal.unitId });
          setSelectedUnit(showItemModal.unitId);
          const removeIds = showItemModal.unitSpecificItem.map(
            (cartItem: any) => cartItem.item.id
          );
          let itemRemoving = [...tempCart];
          itemRemoving = itemRemoving.filter(
            (cartItem) => !removeIds?.includes(cartItem.item.id)
          );
          setTempCart(itemRemoving);
          setShowItemModal(undefined);
        }}
        isModalVisible={!!showItemModal}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text="Your Cart has some items that are specific to previous unit. It will be removed by the current selection of unit. Do you want to continue?"
        okayText="Yes"
        cancelText="No"
      />
    </div>
  );
};

const mapStateToProps = ({ requestState, campusManagement }: IRootState) => ({
  dataLoading: requestState.loading,
  requestDetails: requestState.selectedRequest,
  fetchedUnits: requestState.units,
  unitsLoading: requestState.unitsLoading,
  fetchedPriority: requestState.slas,
  prioritiesLoading: requestState.slasLoading,
  errorMsg: requestState.errorMessage,
  allCampuses: campusManagement.allCampuses,
  campusesLoading: campusManagement.loading,
  editReqLoading: requestState.editReqLoading,
});
const mapDispatchToProps = {
  fetchAllCampuses,
  fetchUnitsOnCampus,
  fetchPriorities,
  updateRequestDetails,
  clearMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestEditModal);
