/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import {
  Row,
  Modal,
  Button,
  Form,
  Input,
  Alert,
  Spin,
  Switch,
  Select,
} from "antd";
import classes from "./campus-modal.module.scss";
import "../../../shared/scss/shared-modal.scss";
import { trimValues } from "../../../../common/utils/trimUtil";
import { CloseOutlined } from "@ant-design/icons";
import {
  EDIT,
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
} from "../../../../common/shared-constants";
import {
  campusNameValidator,
  campusLocationNameValidator,
} from "../../../../common/utils/formValidators";
import { requestorRootInstance } from "../../../../common/config/axios-config";

const CampusModal = ({
  campus,
  mode,
  visible,
  handleCancel,
  handleSubmit,
  errorMsg,
  clearMessages,
  loading,
}: any) => {
  const [submitted, setSubmitted] = useState(false);
  const [getTypes, setGetTypes] = useState<any>([]);
  const [form] = Form.useForm();
  const [prevSelectedRequestTypeIds, setPrevSelectedRequestTypeIds] = useState<
    Array<Number>
  >([]);

  useEffect(() => {
    if (mode === EDIT) {
      const getRequestTypeIds = campus?.requestTypes?.map(
        (type: any) => type.id
      );
      form.setFieldsValue({ requestTypeIds: getRequestTypeIds });
    }
    getRequestType();
  }, []);

  const getRequestType = async () => {
    const res = await requestorRootInstance.get("request-types");
    setGetTypes([...res?.data?.data]);
  };

  const onFormSubmit = async (values: any) => {
    if (submitted) {
      return;
    }
    setSubmitted(true);
    const trimedValues = trimValues(values);
    await handleSubmit(trimedValues);
    setSubmitted(false);
  };

  const requestTypeOnchangeHandler = (e: Array<Number>) => {
    let selectedRequestTypeIds = [...e];
    // Determine added and removed items
    const addedItems = e.filter(
      (id) => !prevSelectedRequestTypeIds.includes(id)
    );
    const removedItems = prevSelectedRequestTypeIds.filter(
      (id) => !e.includes(id)
    );
    // If RETURN_REQUEST_TYPE_ID is added, add PROCEDURE_REQUEST_TYPE_ID
    if (
      addedItems.includes(RETURN_REQUEST_TYPE_ID) &&
      !selectedRequestTypeIds.includes(PROCEDURE_REQUEST_TYPE_ID)
    ) {
      selectedRequestTypeIds.push(PROCEDURE_REQUEST_TYPE_ID);
    }

    // If PROCEDURE_REQUEST_TYPE_ID is removed, remove RETURN_REQUEST_TYPE_ID
    if (
      removedItems.includes(PROCEDURE_REQUEST_TYPE_ID) &&
      selectedRequestTypeIds.includes(RETURN_REQUEST_TYPE_ID)
    ) {
      selectedRequestTypeIds = selectedRequestTypeIds.filter(
        (id) => id !== RETURN_REQUEST_TYPE_ID
      );
    }

    form.setFieldsValue({
      requestTypeIds: Array.from(new Set(selectedRequestTypeIds)),
    });

    // Update previous selection state
    setPrevSelectedRequestTypeIds(selectedRequestTypeIds);
  };

  return (
    <React.Fragment>
      <Modal
        title={
          <div className="commonModalHeader">
            <div className="title">
              <p> {mode === EDIT ? "Edit Campus" : "Create Campus"} </p>
            </div>
            <div className="close">
              <Button className="closeBtn" onClick={handleCancel}>
                <CloseOutlined className="close-icon" />
              </Button>
            </div>
          </div>
        }
        visible={visible}
        width={500}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {errorMsg ? (
          <Alert
            message={errorMsg}
            type="error"
            closable
            afterClose={clearMessages}
            className={`fadeIn ${classes.errorContainer}`}
          />
        ) : null}
        <Form
          form={form}
          layout="horizontal"
          initialValues={campus}
          onFinish={onFormSubmit}
        >
          <Row>
            <Form.Item name="id" hidden />
            <Form.Item
              className={classes.fieldWidth}
              name="name"
              label="Name"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                () => ({
                  validator: campusNameValidator,
                  required: true,
                }),
              ]}
            >
              <Input
                autoFocus={mode !== EDIT}
                placeholder="Enter here"
                className="inputStyle"
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              className={classes.fieldWidth}
              name="location"
              label="Location"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                () => ({
                  validator: campusLocationNameValidator,
                  required: true,
                }),
              ]}
            >
              <Input placeholder="Enter here" className="inputStyle" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              className={classes.fieldWidth}
              name="bannerMessage"
              label="Banner Text"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                {
                  required: false,
                  max: 250,
                  message: "Banner Text should be a maximum of 250 characters.",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter here"
                className="inputStyle"
                autoSize={{ minRows: 1, maxRows: 15 }}
              />
            </Form.Item>
          </Row>
          <Form.Item
            name="bannerStatus"
            label="Show Banner Text"
            valuePropName="checked"
            initialValue={false}
            wrapperCol={{ span: 16 }}
            labelCol={{ span: 8 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="requestTypeIds"
            label="Request Type"
            wrapperCol={{ span: 18 }}
            labelCol={{ span: 6 }}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "Please select at least one request type",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select request types"
              onChange={requestTypeOnchangeHandler}
            >
              {getTypes?.map((type: any) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="modalBtn">
            <Form.Item className={classes.cancelButton}>
              <Button
                className="cancelBtn"
                htmlType="reset"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button className="submitBtn" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default CampusModal;
