/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import { Button, Col, Dropdown, Layout, Menu, Row, Typography } from "antd";
import classes from "./header.module.scss";
import logo from "../../../images/Logo.png";
import userIcon from "../../../images/user-icon.png";
import logoutIcon from "../../../images/logout.png";
import dropImg from "../../../images/Login/ellipsis.png";
import myOrder from "../../../images/Login/myorder.png";
import { IRootState } from "../../../common/redux/reducers";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  exitWithoutLogin,
  login,
  logoutUser,
} from "../../modules/login/actions";
import { useState, useRef } from "react";
import { useEffect } from "react";
import CampusSelectorModal from "../components/campus-select-modal/campus-select-modal";
import { fetchCurrentCampusData } from "../../modules/home/action";
import msalInstance from "../../../common/utils/sso-config";
import AnonymousModal from "../../../common/utils/anonymous-popup";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import CustomAlert from "../../../common/utils/custom-alert";
import { sendEmail } from "../../../common/utils/send-email";

const { Header } = Layout;
const { Title } = Typography;

const HeaderComp = ({
  account,
  logoutUser,
  exitWithoutLogin,
  login,
  currentCampus,
  fetchCurrentCampusData,
}: any) => {
  const [campusModal, setCampusModal] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [anonymousMoodal, setAnonymousModal] = useState(false);
  const [ssoError, setSsoError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    const localCurrentCampus: any = localStorage.getItem("currentCampusId");
    if (localCurrentCampus) {
      fetchCurrentCampusData({ id: localCurrentCampus });
    }
  }, []);

  useEffect(() => {
    const timeout = loginError && setTimeout(() => setLoginError(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [loginError]);

  const history = useHistory();
  const location = useLocation();
  const loginRef: any = useRef(null);

  const loginRequest = async () => {
    loginRef?.current?.blur();
    setLoginLoading(true);
    await msalInstance
      .loginPopup()
      .then(async (ssoResponse: any) => {
        if (ssoResponse.idTokenClaims) {
          localStorage.setItem(
            "logoutHint",
            ssoResponse.idTokenClaims.login_hint
          );
          let loginPayload = {
            name: ssoResponse.idTokenClaims.name.split(",")[1]
              ? `${ssoResponse.idTokenClaims.name.split(",")[1]} ${
                  ssoResponse.idTokenClaims.name.split(",")[0]
                }`
              : ssoResponse.idTokenClaims.name.split(",")[0],
            email: ssoResponse.idTokenClaims.preferred_username,
            employeeId: ssoResponse.idTokenClaims.employeeid,
          };
          const loginResponse = await login(loginPayload);
          if (loginResponse.status === "success") {
            setLoginError("");
          } else {
            setLoginError(loginResponse.errorMessage);
          }
        } else {
          setAnonymousModal(true);
          setSsoError("SSO Authentication failed!");
        }
      })
      .catch((err: any) => {
        sendEmail(err);
        setAnonymousModal(true);
        setSsoError(err.message);
      });
    setLoginLoading(false);
  };

  const logout = async () => {
    setLogoutLoading(true);
    if (process.env.REACT_APP_SSO_ENABLED === "true") {
      const logoutHint: any = localStorage.getItem("logoutHint");
      msalInstance
        .logoutPopup({ logoutHint: logoutHint })
        .then((logoutResponse) => {
          logoutUser();
          history.push("/request");
          localStorage.removeItem("logoutHint");
          setLoginError("");
          setLogoutLoading(false);
        })
        .catch((err) => {
          setLoginError(err.message);
          setLogoutLoading(false);
        });
    } else {
      logoutUser();
      history.push("/request");
      setLogoutLoading(false);
    }
  };
  let imageStyle = {
    filter: isHover
      ? "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)"
      : "brightness(0) saturate(100%) invert(49%) sepia(46%) saturate(565%) hue-rotate(60deg) brightness(96%) contrast(87%)",

    WebkitFilter: isHover
      ? "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)"
      : "brightness(0) saturate(100%) invert(49%) sepia(46%) saturate(565%) hue-rotate(60deg) brightness(96%) contrast(87%)",
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        style={{ backgroundColor: "#ddd", color: "rgba(0,0,0,.85)" }}
        disabled
      >
        <div className={`menuList`}>
          <img
            src={userIcon}
            style={{ backgroundColor: "transparent !important", width: "25px" }}
            alt="user-profile"
          />
          <b>{account?.name || account?.username}</b>
        </div>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => {
          history.push("/request/my-requests");
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className="menuList">
          <img src={myOrder} alt="my-order" style={imageStyle} />
          <span>My Requests</span>
        </div>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={logout}
        disabled={logoutLoading}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className="menuList">
          <img src={logoutIcon} alt="logOut" style={imageStyle} />
          <span>Log Out</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header className="commonHeader">
        <div className="iconsContainer"></div>
        <div className="logo-responsive">
          <Link to="/request">
            <img alt="" src={logo} />
          </Link>
          <span className={classes.selectedCampus}>
            <b style={{ marginRight: "2px" }}>Selected Campus: </b>
            {currentCampus?.id ? (
              <b style={{ marginRight: "2px" }}>
                <u>{currentCampus?.name}</u>
              </b>
            ) : (
              "No Campus Selected"
            )}{" "}
            (
            <span
              title="Click to change campus"
              className={classes.switch}
              onClick={() => setCampusModal(true)}
            >
              switch
            </span>
            )
          </span>
        </div>
        <div className="title-resonsive">
          <Title level={4}>Home</Title>
        </div>
        <div className="iconsContainer">
          {!account &&
            location.pathname === "/request" &&
            process.env.REACT_APP_SSO_ENABLED === "true" && (
              <Button
                className="nextBtn"
                onClick={loginRequest}
                ref={loginRef}
                loading={loginLoading}
              >
                LOGIN
              </Button>
            )}
          {/* {account && (
            <div>
              <img alt="" src={userIcon} className="userIcon" />
              <span className="usernameContainer">
                {account.name || account.username}
              </span>
            </div>
          )} */}
          {/* {account ? (
          <div onClick={logout} className="logoutContainer">
            <img alt="" src={logoutIcon} className="logoutIcon" />
          </div>
        ) : null} */}
          {account && (
            <Dropdown
              className="loginMenu"
              overlayClassName="loginDropdownMenu"
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              overlay={menu}
              trigger={["click"]}
            >
              <span
                className="ant-dropdown-link"
                style={{ cursor: "pointer" }}
                onClick={(e) => e.preventDefault()}
              >
                <img src={dropImg} alt="" />
              </span>
            </Dropdown>
          )}
        </div>
        <CampusSelectorModal
          showModal={campusModal}
          closeModal={() => setCampusModal(false)}
        />
      </Header>
      <span className={classes.selectedCampus1}>
        <b>Selected Campus: </b>
        {currentCampus?.id ? (
          <b>
            <u>{currentCampus?.name}</u>
          </b>
        ) : (
          "No Campus Selected"
        )}{" "}
        (
        <span
          title="Click to change campus"
          className={classes.switch}
          onClick={() => setCampusModal(true)}
        >
          switch
        </span>
        )
      </span>

      {!!loginError && (
        <Row justify="center" style={{ marginTop: 25 }}>
          <Col span={18}>
            <CustomAlert
              message={loginError}
              onCancel={() => setLoginError("")}
            />
          </Col>
        </Row>
      )}
      <AnonymousModal
        key="info-modal"
        handleCancel={() => {
          setLoginError(ssoError);
          setAnonymousModal(false);
        }}
        handleOk={() => {
          setAnonymousModal(false);
        }}
        isModalVisible={anonymousMoodal}
        icon={<ExclamationCircleTwoTone style={{ fontSize: "50px" }} />}
        text={"The sso server is currently down. Please try after some time"}
        okayText="Ok"
      />
    </>
  );
};

const mapStateToProps = ({
  authentication: { account },
  requestData: { campuses: allCampuses },
  homeReducer: { currentCampus },
}: IRootState) => ({
  account,
  currentCampus,
  allCampuses,
});

export default connect(mapStateToProps, {
  logoutUser,
  exitWithoutLogin,
  fetchCurrentCampusData,
  login,
})(HeaderComp);
