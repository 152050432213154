import { adminRootInstance } from "../../../common/config/axios-config";

import {
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_ORDERS_START,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAIL,
  UPDATE_ORDER,
  FETCH_DASHBOARD_ALL_CAMPUSES_SUCCESS,
  FETCH_REQUEST_ALL_CAMPUSES_FAIL,
  INCREMENT_STATUS_COUNT,
  FETCH_DASHBOARD_ALL_BUILDINGS_SUCCESS,
  FETCH_DASHBOARD_ALL_BUILDINGS_FAIL,
  FETCH_DASHBOARD_ALL_CAMPUSES_START,
} from "./action-types";
import { UPDATE_COUNT } from "../../shared/layout/notification/action-types";
import moment from "moment";

export const fetchOrders =
  ({ filter, search, limit, page }: any, hideLoader?: boolean) =>
  async (dispatch: any) => {
    const queryParams = {
      campus: filter.campusId,
      catalogue: filter.catalogue,
      building: filter.building?.toLowerCase() || "",
      searchKeyword: search,
      allOrder: filter.allOrder,
      from: `${
        filter.dateRange?.length === 2
          ? moment(filter?.dateRange[0]).format("YYYY-MM-DD 00:00:00")
          : ""
      }`,
      to: `${
        filter.dateRange?.length === 2
          ? moment(filter?.dateRange[1]).format("YYYY-MM-DD 23:59:59")
          : ""
      }`,
      pageSize: limit,
      page,
    };
    !hideLoader &&
      dispatch({
        type: FETCH_ORDERS_START,
      });
    await adminRootInstance
      .get("orders", {
        params: { ...queryParams },
      })
      .then((res: any) => {
        dispatch({
          type: FETCH_ORDERS_SUCCESS,
          payload: {
            orders: res.data.data.orders,
            totalCount: res.data.data.pagination.totalCount,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ORDERS_FAIL,
          payload: { fetchErrorMessage: err.response?.data?.message },
        });
      });
  };

export const fetchSummaryCounts = () => async (dispatch: any) => {
  await adminRootInstance
    .get("order/count")
    .then((res: any) => {
      dispatch({
        type: FETCH_SUMMARY_SUCCESS,
        payload: {
          summary: res.data.data,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SUMMARY_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

export const updateOrder = (res: any) => async (dispatch: any) => {
  dispatch({
    type: UPDATE_ORDER,
    payload: res,
  });
  res.oldStatus !== res.newStatus &&
    dispatch({
      type: UPDATE_COUNT,
      payload: res,
    });
};

export const addNewOrderToSummaryCount = () => async (dispatch: any) => {
  dispatch({
    type: INCREMENT_STATUS_COUNT,
    payload: { status: "open" },
  });
};
export const fetchCampuses = () => async (dispatch: any) => {
  dispatch({ type: FETCH_DASHBOARD_ALL_CAMPUSES_START });
  return await adminRootInstance
    .get(`campuses/all`)
    .then((res: any) => {
      dispatch({
        type: FETCH_DASHBOARD_ALL_CAMPUSES_SUCCESS,
        payload: {
          campuses: res.data.data,
        },
      });
      return { campuses: res.data.data };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_REQUEST_ALL_CAMPUSES_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};

export const fetchBuildings = () => async (dispatch: any) => {
  return await adminRootInstance
    .get(`buildings`)
    .then((res: any) => {
      dispatch({
        type: FETCH_DASHBOARD_ALL_BUILDINGS_SUCCESS,
        payload: {
          buildings: res.data.data,
        },
      });
      return { buildings: res.data.data };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_DASHBOARD_ALL_BUILDINGS_FAIL,
        payload: { errorMessage: err.response?.data?.message },
      });
    });
};
