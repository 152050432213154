import { Avatar, Typography } from "antd";
import React from "react";
import classes from "./dashboard.module.scss";
const { Text } = Typography;
const avatarStyle = { backgroundColor: "#54983e", verticalAlign: "middle" };

const CountSummary = ({ summary, errorMsg, dataConfig }: any) => {
  return (
    <>
      {errorMsg ? (
        <p>{errorMsg}</p>
      ) : (
        <div className={classes.popoverContainer}>
          {dataConfig?.map(({ label, datakey }: any, index: number) => (
            <div className={classes.popoverList} key={index}>
              <Text>{label}</Text>
              <Avatar style={avatarStyle} size="small">
                {summary[datakey]}
              </Avatar>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CountSummary;
