/* eslint-disable react-hooks/exhaustive-deps*/
import { Helmet } from "react-helmet";
import {
  CHECKIN_TEXT,
  RETIRED_ASSET,
} from "../../../../common/shared-constants";
import { useState } from "react";
import TableComp from "../AIMDashboard/components/TableComp/TableComp";
import { CLEAR_ALL_CHECKIN_MESSAGES } from "./action-types";
import classes from "./AssetCheckin.module.scss";

function AssetCheckIn({
  fetchCheckInData,
  checkinAssets,
  checkinAssetsLoading,
  checkinErrorMessage,
  clearMessage,
  checkinFilterData,
  allCampuses,
  setErrorMsgCheckin,
  errorMsgCheckin,
  manualScanInputCheckin,
  barcodeReadCheckin,
  filterConfigCheckin,
  tableOptCheckin,
  setOperationCheckin,
  checkinSearch,
  onFilterSubmitCheckin,
  onFilterResetCheckin,
  onSearchCheckin,
  checkinFilterInitialValues,
  onFocusSearch,
  manualSubmitCheckin,
}: any) {
  const [showEditModal, setShowEditModal] = useState(false);

  //Table Columns
  const nullRenderer = (value: any) => (value ? value : "-");
  const commonObj = {
    align: "center",
    ellipsis: true,
  };
  const columns: any = [
    {
      ...commonObj,
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Control number",
      dataIndex: "controlNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Serial number",
      dataIndex: "serialNumber",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Campus",
      dataIndex: "campus",
      width: 65,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Status",
      dataIndex: "status",
      width: 60,
      render: (value: any, record: any) =>
        record.deleted ? RETIRED_ASSET : value || "-", //Check if deleted flag is there & show status
    },
    {
      ...commonObj,
      title: "Check In",
      dataIndex: "checkInTime",
      width: 60,
      render: nullRenderer,
    },
    {
      ...commonObj,
      title: "Check In by",
      dataIndex: "checkInBy",
      width: 60,
      render: nullRenderer,
    },
  ];
  return (
    <>
      {/* Hidden input field for barcode focussing purpose */}
      <input
        id="hiddenInputCheckin"
        autoFocus
        autoComplete="false"
        className={classes.hiddenInput}
      />
      <Helmet>
        <title>Asset Checkin-Decon | AIM</title>
      </Helmet>
      <div
        className="checkInTable"
        style={{ border: "1px solid orange !important" }}
      >
        <TableComp
          title="Asset Check In"
          from={CHECKIN_TEXT}
          data={checkinAssets.assets}
          dataPagination={checkinAssets.pagination}
          onSearch={onSearchCheckin}
          onFilterSubmit={onFilterSubmitCheckin}
          onFilterReset={onFilterResetCheckin}
          columns={columns}
          errorMessage={checkinErrorMessage || errorMsgCheckin}
          loading={checkinAssetsLoading}
          clearMessages={() => {
            clearMessage(CLEAR_ALL_CHECKIN_MESSAGES);
            setErrorMsgCheckin("");
            document.getElementById("hiddenInputCheckin")?.focus();
          }}
          filterData={checkinFilterData}
          filterConfig={filterConfigCheckin}
          tableConfig={tableOptCheckin}
          changeTableConfig={(tableConf: any) => {
            setOperationCheckin(tableConf);
            fetchCheckInData({
              filter: checkinFilterData,
              search: checkinSearch,
              ...tableConf,
            });
            document.getElementById("hiddenInputCheckin")?.focus();
          }}
          filterInitialValues={checkinFilterInitialValues}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          allCampuses={allCampuses}
          scannedAsset={barcodeReadCheckin}
          onSearchKeyPress={manualScanInputCheckin} //afterScan(barcodeReadCheckin);
          onFocusSearch={onFocusSearch}
          manualSubmitCheckin={manualSubmitCheckin}
        />
      </div>
    </>
  );
}

export default AssetCheckIn;
