export const EQUIPMENT_CATALOGUE_ID = 1;
export const SHARPS_CATALOGUE_ID = 2;
export const TRANSPORT_CATALOGUE_ID = 3;

export const DELIVERY_REQUEST_TYPE_ID = 1;
export const PICKUP_REQUEST_TYPE_ID = 2;
export const SHARPS_REQUEST_TYPE_ID = 3;
export const PROCEDURE_REQUEST_TYPE_ID = 4;
export const RETURN_REQUEST_TYPE_ID = 5;
export const DISCHARGE_REQUEST_TYPE_ID = 6;
export const TRANSFER_REQUEST_TYPE_ID = 7;
export const PROCEDURE_TEXT = "Procedure";
export const RETURN_TEXT = "Return";
export const DISCHARGE_TEXT = "Discharge";
export const TRANSFER_TEXT = "Transfer";

export const NEW_REQUEST_TYPES = [
  PROCEDURE_REQUEST_TYPE_ID,
  RETURN_REQUEST_TYPE_ID,
  DISCHARGE_REQUEST_TYPE_ID,
  TRANSFER_REQUEST_TYPE_ID,
];

export const ALL_CAMPUS_ID = 1;
export const ALL_UNIT_ID = 1;

export const SERVER_DOWN = "SERVER_DOWN";
export const SERVER_DOWN_MESSAGE =
  "The application seems to be down temporarily. Please try again later or contact support.";
export const NETWORK_DOWN_MESSAGE =
  "Network not available.Please connect to a stable internet connection";
export const TIMEOUT_ERROR = "ECONNABORTED";

export const DEFAULT_PAGE_LIMIT = 30;
export const DEFAULT_PAGE = 1;
export const VIEW_TEXT = "view";
export const EDIT_TEXT = "edit";
export const DELETE_TEXT = "delete";
export const VIEW = "VIEW";
export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const DELETE = "DELETE";

export const Admin = "ADMIN";
export const Lead = "LEAD";
export const Supervisor = "SUPERVISOR";
export const Techuser = "TECH";
export const Dispatcher = "DISPATCHER";
export const ROLES = [Admin, Lead, Supervisor, Techuser, Dispatcher];

export const COMPLETED = "COMPLETED";
export const UNASSIGNED = "UNASSIGNED";
export const ASSIGNED = "ASSIGNED";

export const CATALOGUE_ALL = "All";
export const BUILDING_ALL = "All";

export const DEFAULT_NOTIFICATION_LIMIT = 5;
export const CANCELLED = "CANCELLED";
export const OVERRIDE_CLOSE = "OVERRIDE CLOSE";

export const CANCEL_STATUS_ID = 5;
export const OVERRIDE_CLOSE_STATUS_ID = 7;

// SOCKET ROOM NAMES
export const SUB_ROOM1 = "admin-notify";
export const SUB_ROOM2 = "admin-dashboard";
export const SUB_ROOM3 = "order-details";
export const SUB_ROOM4 = "ext-my-order";
export const SUB_ROOM5 = "admin-send-csv";

export const UNSUB_ROOM1 = "unsubscribe-admin-notify";
export const UNSUB_ROOM2 = "unsubscribe-admin-dashboard";
export const UNSUB_ROOM3 = "unsubscribe-order-details";
export const UNSUB_ROOM4 = "unsubscribe-ext-my-order";
export const UNSUB_ROOM5 = "unsubscribe-admin-send-csv";
//used in add Item modal to change modal behaviour based on the type
export const PICKUP_REQ = "PICKUP_REQ";
export const SHARP_REQ = "SHARP_REQ";
export const DELIVERY_REQ = "DELIVERY_REQ";
export const REQ_EDIT = "REQ_EDIT";
export const reqTypeAddItemMap: any = {
  [PICKUP_REQUEST_TYPE_ID]: PICKUP_REQ,
  [DELIVERY_REQUEST_TYPE_ID]: DELIVERY_REQ,
  [SHARPS_REQUEST_TYPE_ID]: SHARP_REQ,
};

export const ORDER_STATUS_MAP = [
  { id: 1, name: "Cancelled" },
  { id: 2, name: "Completed (on time)" },
  { id: 3, name: "Completed (late)" },
  { id: 4, name: "Completed (on time) + partial" },
  { id: 5, name: "Completed (late) + partial" },
  { id: 6, name: "Completed (on time) + delayed" },
  { id: 7, name: "Completed (late) + delayed" },
  { id: 8, name: "Completed (on time) + partial + delayed" },
  { id: 9, name: "Completed (late) + partial + delayed" },
  { id: 10, name: "Override close" },
];
export const AVAILABLE_ASSET = "AVAILABLE";
export const INUSE_ASSET = "INUSE";
export const SOILED_ASSET = "SOILED";
export const DECON_ASSET = "DECON";
export const REPAIR_ASSET = "REPAIR";
export const PICKUP_READY_ASSET = "PICKUP READY";
export const RETIRED_ASSET = "RETIRED";
export const RETIRED_ASSET_KEY = "DELETED";
export const STAGING = "STAGING";

export const ASSET_STATUS_MAP = [
  { id: 0, name: "ALL" }, //Added to handle all cases - only in frontend
  { id: 1, name: AVAILABLE_ASSET },
  { id: 2, name: INUSE_ASSET },
  { id: 3, name: SOILED_ASSET },
  { id: 4, name: DECON_ASSET },
  { id: 5, name: REPAIR_ASSET },
  { id: 6, name: PICKUP_READY_ASSET },
  { id: 7, name: RETIRED_ASSET_KEY, label: RETIRED_ASSET },
  { id: 8, name: STAGING },
];

export const CHECKIN_TEXT = "CHECKIN";
export const DECON_TEXT = "DECON";

//Admin Page Types - For casl
export const USER_PAGE = "User";
export const SLA_PAGE = "SLA";
export const CAMPUS_PAGE = "Campus";
export const UNIT_PAGE = "Unit";
export const ITEM_PAGE = "Item";
export const DATA_IMPORTS = "Data imports";
export const TRANSPORT_PAGE = "Transport";
export const AIM_DATA_IMPORTS = "Aim data imports";

//successMessages
export const ORDER_PLACED_SUCCESSFULLY =
  "Your order is successfully placed, We Got it";

//successMessageForPickup
export const PICKUP_CREATED_SUCCESSFULLY =
  "Your pickup request is created successfully, We Got it";

//successMessageForReturn
export const RETURN_CREATED_SUCCESSFULLY =
  "Your return request is created successfully, We Got it";

export const DISCHARGE_CREATED_SUCCESSFULLY =
  "Your discharge request is created successfully, We Got it";

export const TRANSFER_CREATED_SUCCESSFULLY =
  "Your transfer request is created successfully, We Got it";

//constant texts
export const SPECIALTY_HOVER_TITLE = "Specialty Item";
export const SPECIALTY_INPUT_NUMBER_DISABLED_TITLE =
  "Disabled for speciality items";
export const VIEW_PATIENTS_TEXT = "View patients";

//Patient Details modal - Types
export const PATIENTMODAL_SINGLE_NEW = "SINGLE_NEW";
export const PATIENTMODAL_SINGLE_REMOVE = "SINGLE_REMOVE";
export const PATIENTMODAL_VIEW = "VIEW";

// HEADER VARIANTS
export const AIM = "AIM";
export const ADMIN_DASHBOARD = "ADMIN_DASHBOARD";
export const POST_LOGIN_SCREEN = "POST_LOGIN_SCREEN";

//Inline color - Table Buttons
export const BUTTON_GREEN_HEX = "#549e39";
export const GREEN_THEME_HEX = "#54983e";
export const ADMIN_DATE_FORMAT = "MM/DD/YYYY";
