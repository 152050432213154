/* eslint-disable react-hooks/exhaustive-deps*/
import classes from "./TableComp.module.scss";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Popover,
  Row,
  Table,
  Typography,
  Input,
  Form,
  Modal,
  Alert,
  message,
} from "antd";
import searchImg from "../../../../../../images/search-icon.png";
import filterImg from "../../../../../../images/filter.png";
import sigmaImg from "../../../../../../images/sigma.png";
import uploadImg from "../../../../../../images/upload.png";
import rightArrow from "../../../../../../images/AIM/right_arrow.png";
import maximize from "../../../../../../images/maximize.png";
import minimize from "../../../../../../images/minimize.png";
import Summary from "../../../../dashboard/summary";
import TableFilters from "./TableFilters";
import FiltersBreadCrumb from "./FiltersBreadCrumb";
import DataImport from "../../../../data-import/data-import";
import EditModal from "../EditModal/EditModal";
import { Can } from "../../../../../shared/ability/can";
import {
  AIM_DATA_IMPORTS,
  CHECKIN_TEXT,
  DECON_TEXT,
} from "../../../../../../common/shared-constants";
import AIMDownloadCSV from "../../../AIMReports/utils/AIMDownloadCSV";

const { Title } = Typography;
interface TableCompInterface {
  title: string;
  from?: string;
  data: Array<any>;
  dataPagination: any;
  onSearch: (searchString: string) => any;
  onSearchKeyPress?: (e: any) => any;
  onFilterSubmit: (filterData: string) => any;
  onFilterReset: () => any;
  columns: any;
  summaryData?: any;
  summaryConfig?: any;
  loading: boolean;
  errorMessage: string;
  clearMessages: () => void;
  filterData: any;
  filterConfig: any;
  filterInitialValues: any;
  tableConfig: any;
  changeTableConfig: any;
  showEditModal?: boolean;
  setShowEditModal?: (value: boolean) => void;

  allCampuses?: any;
  allUnits?: any;

  onImportResp?: (obj: any) => void;
  editProps?: any;
  onEdit?: (value: any) => void;
  onDelete?: (value: any) => any;
  scannedAsset?: string;
  onFocusSearch?: () => void;
  manualSubmitCheckin?: () => void;
  manualSubmitDecon?: () => void;
}

function TableComp(props: TableCompInterface) {
  const {
    title,
    from,
    data,
    dataPagination: { totalCount } = {},
    onSearch: onSearchCallback,
    onSearchKeyPress,
    onFilterSubmit: onFilterSubmitCallback,
    onFilterReset: onFilterResetCallback,
    columns,
    summaryData,
    summaryConfig,
    errorMessage,
    loading,
    clearMessages,
    filterData,
    filterConfig,
    tableConfig,
    changeTableConfig,
    filterInitialValues,
    showEditModal,
    setShowEditModal = () => {},
    onImportResp,
    allCampuses,
    allUnits,
    editProps,
    onEdit: onEditCallback = () => {},
    onDelete: onDeleteCallback = () => {},
    scannedAsset,
    onFocusSearch,
    manualSubmitCheckin = () => {},
    manualSubmitDecon = () => {},
  } = props;
  const { limit, page, pageSizeOptions, showSizeChanger } = tableConfig;

  const [searchEnabled, setSearchEnabled] = useState(false);
  const [maximizeCheckin, setMaximizeCheckin] = useState(false);
  const [maximizeDecon, setMaximizeDecon] = useState(false);

  useEffect(() => {
    return () => {
      clearMessages();
    };
  }, []);

  useEffect(() => {
    scannedAsset && setIsFilterVisible(false);
    scannedAsset && setSearchEnabled(true);
    return () => {};
  }, [scannedAsset]);
  const [showDataImportModal, setShowDataImportModal] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const searchClick = (e: any) => {
    setSearchEnabled((state: boolean) => !state);
  };
  const onFilterReset = () => {
    onFilterResetCallback();
    form.setFieldsValue(filterInitialValues);
  };
  const onFilterSubmit = (filterData: any) => {
    onFilterSubmitCallback(filterData);
    setIsFilterVisible(false);
  };

  const onSearch = (e: any) => {
    onSearchCallback(e.target.value);
  };
  const handleFilterVisibleChange = (e: any) => {
    form.resetFields();
    setIsFilterVisible((prevVal: boolean) => !prevVal);
  };
  const [form] = Form.useForm();
  const handleTableChange = (pagination: any) => {
    changeTableConfig({
      ...tableConfig,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };
  return (
    <>
      <section className={classes.dashBoardContainer}>
        {title && (
          <Title className={classes.titleText} level={5}>
            {title}
          </Title>
        )}
        <Row>
          <Col span={20}>
            <Row className={classes.filtersContainer}>
              {/* Search button */}
              <div style={{ display: "flex" }}>
                <Button
                  htmlType="button"
                  className={classes.filterBtn}
                  onFocus={(e: any) => {
                    e.target.blur();
                  }}
                  onClick={searchClick}
                >
                  <img
                    src={searchImg}
                    alt="searchIcon"
                    className={classes.btnImg}
                  />
                </Button>
                {searchEnabled && (
                  <Input
                    value={tableConfig.search}
                    className={`${classes.searchContainer} slideOut`}
                    allowClear={true}
                    onChange={onSearch}
                    onFocus={onFocusSearch}
                    {...(onSearchKeyPress
                      ? {
                          onKeyDown: onSearchKeyPress,
                          title:
                            "Press ctrl/command + Enter to manual change status",
                        }
                      : {})}
                    placeholder="Search by Name / Control / Serial number"
                  />
                )}
                {searchEnabled &&
                  (from === CHECKIN_TEXT || from === DECON_TEXT) && (
                    <Button
                      htmlType="button"
                      className={classes.filterBtn}
                      onFocus={(e: any) => {
                        e.target.blur();
                      }}
                      onClick={
                        from === CHECKIN_TEXT
                          ? manualSubmitCheckin
                          : manualSubmitDecon
                      }
                    >
                      <img
                        src={rightArrow}
                        alt="rightArrow"
                        width={"18px"}
                        height={"18px"}
                      />
                    </Button>
                  )}
              </div>
              {/* Filter Button */}
              <Popover
                placement="bottomLeft"
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                title={false}
                content={
                  <TableFilters
                    {...{
                      form,
                      onFilterReset,
                      onFilterSubmit,
                      key: "tableFilter",
                      filterConfig,
                      initialValues: filterData,
                      defaultValues: filterInitialValues,
                    }}
                  />
                }
                trigger="click"
                visible={isFilterVisible}
                onVisibleChange={handleFilterVisibleChange}
                overlayStyle={{ minWidth: 450 }}
              >
                <Button
                  className={classes.filterBtn}
                  onFocus={(e: any) => {
                    e.target.blur();
                  }}
                >
                  <img
                    src={filterImg}
                    alt="filterIcon"
                    className={classes.btnImg}
                  />
                </Button>
              </Popover>
              {/* Summary Button */}
              {summaryData && summaryConfig?.length && (
                <Popover
                  placement="right"
                  getPopupContainer={(trigger) =>
                    trigger.parentNode as HTMLElement
                  }
                  title={false}
                  content={
                    <Summary
                      summary={summaryData}
                      errorMsg={""}
                      dataConfig={summaryConfig}
                    />
                  }
                  trigger="click"
                >
                  <Button className={classes.filterBtn}>
                    <img
                      src={sigmaImg}
                      alt="summationIcon"
                      className={classes.btnImg}
                    />
                  </Button>
                </Popover>
              )}
              <div className={classes.appliedFilters}>
                <span>
                  <b>Active filters:&nbsp;</b>
                </span>
                <FiltersBreadCrumb {...{ filterData, allCampuses, allUnits }} />
              </div>
            </Row>
          </Col>
          {/* Data import button */}
          {onImportResp && (
            <Can I="view" a={AIM_DATA_IMPORTS}>
              <Col span={4}>
                <Row justify="end">
                  <Button
                    type="primary"
                    className={classes.filterBtn}
                    title="Data import"
                    onClick={() => setShowDataImportModal(true)}
                    style={{ marginRight: "10px" }}
                  >
                    <img
                      alt="importIcon"
                      style={{
                        filter:
                          "brightness(0) invert(1) drop-shadow(0 0 0px white)",
                      }}
                      className={classes.btnImg}
                      src={uploadImg}
                    />
                  </Button>
                  {/* Download CSV */}
                  <AIMDownloadCSV
                    filterData={filterData}
                    reportType="AIM Dashboard"
                    searchText={tableConfig?.search}
                  />
                </Row>
              </Col>
            </Can>
          )}
          {(from === CHECKIN_TEXT || from === DECON_TEXT) && (
            <Col span={4} className={classes.minMaxContainer}>
              <div>
                {from === CHECKIN_TEXT && (
                  <Button
                    type="primary"
                    className={classes.filterBtn}
                    onClick={() => setMaximizeCheckin((prev) => !prev)}
                  >
                    <img
                      className={classes.maximize}
                      src={maximizeCheckin ? minimize : maximize}
                    />
                  </Button>
                )}
                {from === DECON_TEXT && (
                  <Button
                    type="primary"
                    className={classes.filterBtn}
                    onClick={() => setMaximizeDecon((prev) => !prev)}
                  >
                    <img
                      className={classes.maximize}
                      src={maximizeDecon ? minimize : maximize}
                    />
                  </Button>
                )}
              </div>
            </Col>
          )}
        </Row>
        {errorMessage ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={errorMessage}
            type="error"
            closable
            afterClose={clearMessages}
          />
        ) : null}
        {/* Data table */}
        <Row gutter={24}>
          <Col span={24}>
            {from === CHECKIN_TEXT ? (
              <Table
                className={classes.mainTable}
                dataSource={data}
                loading={loading}
                columns={columns}
                onChange={handleTableChange}
                size={"small"}
                locale={{ filterEmptyText: true }}
                scroll={
                  from === CHECKIN_TEXT || from === DECON_TEXT
                    ? { x: 1200, y: maximizeCheckin ? 500 : 180 }
                    : { x: 1200 }
                }
                bordered
                pagination={{
                  current: page,
                  pageSize: limit,
                  total: totalCount || 0,
                  pageSizeOptions: pageSizeOptions,
                  showSizeChanger: showSizeChanger,
                  style:
                    from === CHECKIN_TEXT
                      ? { marginTop: "16px", marginBottom: 0 }
                      : {},
                }}
              />
            ) : (
              <Table
                className={classes.mainTable}
                dataSource={data}
                loading={loading}
                columns={columns}
                onChange={handleTableChange}
                size={"small"}
                locale={{ filterEmptyText: true }}
                scroll={
                  from === CHECKIN_TEXT || from === DECON_TEXT
                    ? { x: 1200, y: maximizeDecon ? 500 : 180 }
                    : { x: 1200 }
                }
                bordered
                pagination={{
                  current: page,
                  pageSize: limit,
                  total: totalCount || 0,
                  pageSizeOptions: pageSizeOptions,
                  showSizeChanger: showSizeChanger,
                  style:
                    from === CHECKIN_TEXT
                      ? { marginTop: "16px", marginBottom: 0 }
                      : {},
                }}
              />
            )}
          </Col>
        </Row>
      </section>
      {/* Data import Modal */}
      {showDataImportModal && onImportResp && (
        <Modal
          visible={showDataImportModal}
          onCancel={() => setShowDataImportModal(false)}
          maskClosable={false}
          footer={null}
          width={600}
        >
          <div className={classes.dataImport}>
            <h2 className={classes.heading}>Upload Asset Inventory</h2>
            <DataImport
              apiUrl="aim/mass-upload"
              onSuccess={(successObj) => {
                message.success("File uploaded successfully");
                onImportResp(successObj);
                setShowDataImportModal(false);
              }}
              onFailure={(failureObj) => {
                onImportResp(failureObj);
              }}
              templateFile={{
                data: [
                  [
                    "Item Type",
                    "Control Number",
                    "Serial Number",
                    "Item Category",
                    "Campus",
                  ],
                ],
                fileName: "aim-import-template.csv",
              }}
            />
          </div>
        </Modal>
      )}
      {/* Edit Asset Modal */}
      {
        <EditModal
          {...editProps}
          visible={showEditModal}
          closeModal={() => setShowEditModal(false)}
          onEdit={async (value: any) => await onEditCallback(value)}
          onDelete={async (value: any) => await onDeleteCallback(value)}
        />
      }
    </>
  );
}

export default TableComp;
