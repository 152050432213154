/* eslint-disable react-hooks/exhaustive-deps*/

import {
  Col,
  Row,
  Select,
  Tooltip,
  Input,
  Button,
  Form,
  message,
  Modal,
  Spin,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { adminRootInstance } from "../../../../../../common/config/axios-config";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  AVAILABLE_ASSET,
  INUSE_ASSET,
  SOILED_ASSET,
  DECON_ASSET,
  REPAIR_ASSET,
  PICKUP_READY_ASSET,
  RETIRED_ASSET,
} from "../../../../../../common/shared-constants";
import SingleLineSkeleton from "../../../../../../common/utils/SingleLineSkeleton";
import classes from "./EditModal.module.scss";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getESTFromUTC,
  getFormattedDate,
} from "../../../../../../common/utils/time";

const options = [
  AVAILABLE_ASSET,
  INUSE_ASSET,
  SOILED_ASSET,
  DECON_ASSET,
  REPAIR_ASSET,
  PICKUP_READY_ASSET,
];

export default function EditModal(props: any) {
  const {
    controlNumber,
    equipmentName,
    status,
    serialnumber,
    lastLocation,
    lastCleanedBy,
    orderNumber = "",
    specialty,
    lastUpdated,
    repairedAt,
    rental,
    notes,
    closeModal,
    id,
    onEdit,
    onDelete,
    visible,
    deleted,
    deletedBy,
    updatedBy,
    campus,
  } = props;
  const initialLoader = { edit: false, delete: false };
  const deleteResp: any = useRef({});
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [updatedData, setUpdatedData] = useState<any>({ loading: true });
  const [loaders, setLoaders] = useState<any>(initialLoader);
  const [currentControlNumber, setCurrentControlNumber] =
    useState(controlNumber);
  const [currentSerialNumber, setCurrentSerialNumber] = useState(serialnumber);
  const [currentEquipmentType, setCurrentEquipmentType] =
    useState(equipmentName);
  const [controlNoEmpty, setControlNoEmpty] = useState(false);
  const [serialNoEmpty, setSerialNoEmpty] = useState(false);

  useEffect(() => {
    form.resetFields();
    id &&
      (async () => {
        await adminRootInstance
          .get(`aim/${id}`)
          .then((res: any) => {
            setUpdatedData({ ...res.data.data, loading: false });
          })
          .catch((error) => {
            setUpdatedData({
              error: error.response?.data?.message,
              loading: false,
            });
          });
      })();
    return () => {
      setUpdatedData({ loading: true });
      setLoaders(initialLoader);
    };
  }, [id]);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    setSelectedStatus(status);
    setCurrentControlNumber(controlNumber);
    setCurrentSerialNumber(serialnumber);
    setCurrentEquipmentType(equipmentName);
  }, [visible]);
  const itemCategoryString =
    updatedData?.itemCategory?.length &&
    updatedData.itemCategory?.reduce(
      (prev: string, curr: string) => `${prev}, ${curr}`
    );
  const config = [
    {
      label: "Status",
      value:
        updatedData?.deleted || deleted
          ? RETIRED_ASSET
          : updatedData?.status || status || "-",
      tooltip: status,
      parentWidth: 8,
    },
    {
      label: "Last location",
      value: updatedData?.lastLocation || lastLocation || "-",
      tooltip: updatedData?.lastLocation || lastLocation,
      parentWidth: 8,
    },
    {
      label: "Last Room/BS",
      value: updatedData.lastRoomName || "-",
      tooltip: updatedData.lastRoomName,
      parentWidth: 8,
    },
    {
      label: "Campus",
      value: updatedData?.campus || campus || "-",
      tooltip: updatedData?.campus || campus,
      parentWidth: 8,
    },
    {
      label: "Last cleaned by",
      value: updatedData.loading ? (
        <SingleLineSkeleton width={150} />
      ) : (
        updatedData.lastCleanedBy || lastCleanedBy || "-"
      ),
      tooltip: updatedData?.lastCleanedBy || lastCleanedBy || undefined,
      parentWidth: 8,
    },
    {
      label: "Last order",
      value: updatedData.loading ? (
        <SingleLineSkeleton width={150} />
      ) : (
        updatedData.orderNumber || orderNumber || "-"
      ),
      tooltip: updatedData?.orderNumber || orderNumber || undefined,
      parentWidth: 8,
    },
    {
      label: "Speciality",
      value: updatedData?.specialty || specialty ? "Yes" : "No",
      tooltip: updatedData?.specialty || specialty ? "Yes" : "No",
      parentWidth: 8,
    },
    {
      label: "Rental",
      value:
        updatedData?.specialty || specialty
          ? updatedData?.rental || rental
            ? "Yes"
            : "No"
          : "-",
      tooltip:
        updatedData?.specialty || specialty
          ? updatedData?.rental || rental
            ? "Yes"
            : "No"
          : "Not Applicable",
      parentWidth: 8,
    },
    (updatedData?.deleted || deleted) && {
      label: "Retired on",
      value: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(updatedData?.lastUpdated || lastUpdated)
        : getESTFromUTC(updatedData?.lastUpdated || lastUpdated) || "-",
      tooltip: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(updatedData?.lastUpdated || lastUpdated)
        : getESTFromUTC(updatedData?.lastUpdated || lastUpdated) ||
          "Not Applicable",
      parentWidth: 8,
    },
    (updatedData?.deleted || deleted) && {
      label: "Retired by",
      value: updatedData?.deletedBy || deletedBy || "-",
      tooltip: updatedData?.deletedBy || deletedBy,
      parentWidth: 8,
    },
    (updatedData?.status === REPAIR_ASSET || status === REPAIR_ASSET) && {
      label: "Repair Started on",
      value: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(updatedData?.repairedAt || repairedAt)
        : getESTFromUTC(updatedData?.repairedAt || repairedAt) || "-",
      tooltip: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(updatedData?.repairedAt || repairedAt)
        : getESTFromUTC(updatedData?.repairedAt || repairedAt) ||
          "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Last modified at",
      value: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(updatedData?.lastUpdated || lastUpdated)
        : getESTFromUTC(updatedData?.lastUpdated || lastUpdated) || "-",
      tooltip: process.env.REACT_APP_SSO_ENABLED
        ? getFormattedDate(updatedData?.lastUpdated || lastUpdated)
        : getESTFromUTC(updatedData?.lastUpdated || lastUpdated) ||
          "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Last modified by",
      value: updatedData?.updatedBy || updatedBy || "-",
      tooltip: updatedData?.updatedBy || updatedBy || "Not Applicable",
      parentWidth: 8,
    },
    {
      label: "Item category",
      value: updatedData.loading ? (
        <SingleLineSkeleton width={350} />
      ) : (
        itemCategoryString || "-"
      ),
      tooltip: itemCategoryString || undefined,
      parentWidth: 8,
    },
    (updatedData?.deleted || deleted) &&
    (updatedData?.notes || notes) &&
    (updatedData?.status || status) === REPAIR_ASSET
      ? {
          label: "Repair Description",
          value: updatedData?.notes || notes || "-",
          tooltip: "Repair Notes" || undefined,
          parentWidth: 24,
        }
      : null,
  ];
  const controlNumberChange = (e: any) => {
    setCurrentControlNumber(e.target.value);
    e.target.value.trim() ? setControlNoEmpty(false) : setControlNoEmpty(true);
  };
  const serialNumberChange = (e: any) => {
    setCurrentSerialNumber(e.target.value);
    e.target.value.trim() ? setSerialNoEmpty(false) : setSerialNoEmpty(true);
  };

  const dataBody = (
    <Row align="middle" className={classes.contentBody}>
      <Col span={8} className={`${classes.dataField} `}>
        <Row justify="start" align="bottom">
          <Col span={24}>
            <Row align="bottom" style={{ width: "100%" }}>
              <label>Equipment Type</label>
              <Input
                className={classes.ellipsis}
                placeholder="Equipment type"
                value={currentEquipmentType}
                onChange={(e: any) => setCurrentEquipmentType(e.target.value)}
                disabled
              />
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={8} className={`${classes.dataField} `}>
        <Row justify="start" align="bottom">
          <Col span={24}>
            <Row align="bottom" style={{ width: "100%" }}>
              <label> Control number</label>
              <Input
                className={classes.ellipsis}
                placeholder="Control number"
                value={currentControlNumber}
                onChange={controlNumberChange}
                style={
                  controlNoEmpty
                    ? { borderColor: "#ff4d4f", boxShadow: "none" }
                    : {}
                }
                disabled={updatedData?.deleted}
              />
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={8} className={`${classes.dataField} `}>
        <Row justify="start" align="bottom">
          <Col span={24}>
            <Row align="bottom" style={{ width: "100%" }}>
              <label> Serial number</label>
              <Input
                className={classes.ellipsis}
                placeholder="Serial number"
                value={currentSerialNumber}
                onChange={serialNumberChange}
                style={
                  serialNoEmpty
                    ? { borderColor: "#ff4d4f", boxShadow: "none" }
                    : {}
                }
                disabled={updatedData?.deleted}
              />
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={8}></Col>

      <Col span={8}>
        {controlNoEmpty && (
          <span style={{ color: "#ff4d4f", marginLeft: "5px" }}>
            Control number cannot be empty
          </span>
        )}
      </Col>

      <Col span={8}>
        {serialNoEmpty && (
          <span style={{ color: "#ff4d4f", marginLeft: "5px" }}>
            Serial number cannot be empty
          </span>
        )}
      </Col>

      {config.map((configObj: any) =>
        configObj ? (
          <Col span={configObj.parentWidth} className={`${classes.dataField} `}>
            <Row justify="start" align="bottom">
              <Col span={24}>
                <Row align="bottom" style={{ width: "100%" }}>
                  <label>{configObj.label}:</label>
                  <Tooltip
                    title={configObj.tooltip}
                    className={classes.ellipsis}
                    overlayInnerStyle={{ width: "max-content" }}
                    arrowPointAtCenter
                    destroyTooltipOnHide
                  >
                    <span
                      style={{
                        paddingLeft: "5px",
                        maxWidth: `${(configObj.parentWidth / 8) * 200}px`,
                      }}
                    >
                      {configObj.value}
                    </span>
                  </Tooltip>
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          <React.Fragment />
        )
      )}
    </Row>
  );
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        closeModal();
        setControlNoEmpty(false);
        setSerialNoEmpty(false);
      }}
      maskClosable={false}
      footer={null}
      width={1000}
      closable={!(loaders.edit || loaders.delete)}
      confirmLoading={true}
    >
      <Col span={24} key={id}>
        <Row justify="center" className={classes.title}>
          Asset Details
        </Row>
        {updatedData.loading ? <Spin>{dataBody}</Spin> : dataBody}
        {!deleted && (
          <Row justify="center">
            <Col span={24}>
              <Row className={classes.contentBody} align="middle">
                <Col span={8} className={`${classes.dataField}`}>
                  <Tooltip title="">
                    <label>Change Status:</label>{" "}
                    <Select
                      className={classes.statusSelect}
                      allowClear
                      placeholder={status}
                      value={selectedStatus}
                      onClear={() => setSelectedStatus(status)}
                      onChange={(val: any) => val && setSelectedStatus(val)}
                      size="middle"
                    >
                      {status === REPAIR_ASSET ||
                      status === PICKUP_READY_ASSET ? (
                        <Select.Option
                          value={AVAILABLE_ASSET}
                          style={{ fontSize: "13px" }}
                        >
                          {AVAILABLE_ASSET}
                        </Select.Option>
                      ) : rental ? (
                        status === INUSE_ASSET ? (
                          <Select.Option
                            value={PICKUP_READY_ASSET}
                            style={{ fontSize: "13px" }}
                          >
                            {PICKUP_READY_ASSET}
                          </Select.Option>
                        ) : (
                          options.map(
                            (option) =>
                              option != INUSE_ASSET &&
                              option != SOILED_ASSET &&
                              option != DECON_ASSET && (
                                <Select.Option
                                  value={option}
                                  style={{ fontSize: "13px" }}
                                >
                                  {option}
                                </Select.Option>
                              )
                          )
                        )
                      ) : (
                        options.map(
                          (option) =>
                            option != PICKUP_READY_ASSET &&
                            option != INUSE_ASSET && (
                              <Select.Option
                                value={option}
                                style={{ fontSize: "13px" }}
                              >
                                {option}
                              </Select.Option>
                            )
                        )
                      )}
                    </Select>
                  </Tooltip>
                </Col>
                {notes && status === REPAIR_ASSET && (
                  <Col span={16} className={classes.dataField}>
                    <label>Repair Description:</label>
                    <span style={{ marginLeft: "5px" }}>{notes}</span>
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24} className={classes.repairDesc}>
              <Form
                form={form}
                onFinish={async (val: any) => {
                  if (
                    !currentControlNumber.trim() &&
                    !currentSerialNumber.trim()
                  ) {
                    setControlNoEmpty(true);
                    setSerialNoEmpty(true);
                    return;
                  } else if (!currentControlNumber.trim()) {
                    setControlNoEmpty(true);
                    return;
                  } else if (!currentSerialNumber.trim()) {
                    setSerialNoEmpty(true);
                    return;
                  } else {
                    setControlNoEmpty(false);
                    setSerialNoEmpty(false);
                  }
                  setLoaders((loadObj: any) => ({ ...loadObj, edit: true }));
                  const resp = await onEdit({
                    ...val,
                    status: status,
                    selectedStatus: selectedStatus,
                    currentControlNumber: currentControlNumber,
                    currentSerialNumber: currentSerialNumber,
                  });
                  setLoaders((loadObj: any) => ({ ...loadObj, edit: false }));
                  if (resp.success) {
                    message.success("Asset updated successfully!");
                    closeModal();
                    setControlNoEmpty(false);
                    setSerialNoEmpty(false);
                  } else
                    message.error(resp?.message || "Something went wrong!");
                }}
              >
                {selectedStatus === REPAIR_ASSET && status !== REPAIR_ASSET && (
                  <Form.Item
                    name="notes"
                    style={{ width: "100%" }}
                    initialValue={""}
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (!value || value.trim().length < 1)
                            return Promise.reject("Enter a valid reason!");
                          else if (value.trim().length > 200)
                            return Promise.reject(
                              `Reason should be less than or equal to ${200} character long!`
                            );
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.TextArea
                      className={classes.notes}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder="Enter Repair Description"
                      onKeyDown={(event: any) => {
                        if (
                          (event.keyCode === 10 || event.keyCode === 13) &&
                          event.ctrlKey
                        )
                          form.submit();
                      }}
                    />
                  </Form.Item>
                )}

                <Row justify="center" className={classes.submitButton}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="nextBtn"
                    loading={loaders.edit}
                  >
                    <SaveOutlined />
                    Save & Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    className="nextBtn "
                    style={{ marginLeft: "50px" }}
                    loading={loaders.delete}
                    onClick={async () => {
                      Modal.confirm({
                        maskClosable: false,
                        title: "Confirm Retirement",
                        icon: (
                          <ExclamationCircleOutlined
                            style={{ color: "rgba(0, 0, 0, 0.85)" }}
                          />
                        ),

                        content: "Are you sure you want to retire this asset?",
                        okText: "I'm sure",
                        cancelText: "No",
                        className: "sharpDelayModal",
                        afterClose: () => {
                          if (deleteResp.current?.success) {
                            closeModal();
                            setControlNoEmpty(false);
                            setSerialNoEmpty(false);
                            deleteResp.current = {};
                          }
                        },
                        onOk: async () => {
                          setLoaders((loadObj: any) => ({
                            ...loadObj,
                            delete: true,
                          }));
                          const resp = await onDelete({ assetId: id });
                          deleteResp.current = resp;
                          setLoaders((loadObj: any) => ({
                            ...loadObj,
                            delete: false,
                          }));
                          if (resp.success) {
                            message.success("Asset Retired successfully!");
                            closeModal();
                            setControlNoEmpty(false);
                            setSerialNoEmpty(false);
                          } else {
                            message.error(resp.message);
                          }

                          return resp;
                        },
                        onCancel() {
                          deleteResp.current = {};
                        },
                        okButtonProps: {
                          className: "nextBtn",
                        },
                        cancelButtonProps: {
                          className: "backBtn",
                        },
                      });
                    }}
                  >
                    <DeleteOutlined />
                    Retire Asset
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </Col>
    </Modal>
  );
}
