import { Col, Row } from "antd";
import ImageButton from "../../shared/components/ImageButton/ImageButton";
import DashboardImage from "../../../images/AIM/dashboard_W.png";
import AIMImage from "../../../images/AIM/Asset Management.png";

import classes from "./AdminPostLogin.module.scss";
import { useSelector } from "react-redux";
import { IRootState } from "../../../common/redux/reducers";
import { Techuser } from "../../../common/shared-constants";
import { Redirect } from "react-router-dom";
function AdminPostLogin() {
  const loggedInUser: any = useSelector(
    (state: IRootState) => state.adminAuthentication.account
  );
  if (loggedInUser?.role === Techuser)
    return <Redirect to="/admin/aim/dashboard" />;
  return (
    <Row className={classes.postLoginPage} align="middle" justify="center">
      <Col className={classes.routeButton}>
        <ImageButton
          linkText="Dashboard"
          url="/admin/dashboard"
          image={DashboardImage}
        />
      </Col>
      <Col className={classes.routeButton}>
        <ImageButton linkText="AIM" url="/admin/aim" image={AIMImage} />
      </Col>
    </Row>
  );
}
export default AdminPostLogin;
